.fullpage-form {
  padding-bottom: 15px;

  .fullpage-form-row {
    box-shadow: var(--box-shadow);
    background-color: var(--fullpage-form-color-bg);
  }

  .fullpage-form-text {
    text-align: center;
    padding: 50px 15px;
    background-color: var(--fullpage-form-color-bg);

    h1 {
      font-size: 26px;
    }

    img {
      height: 150px;
      width: auto;
    }

    ul {
      text-align: left;
      display: inline-block;
      margin: 15px 20% 30px;
    }
  }

  .fullpage-form-col {
    padding: 25px 15px 15px;
  }
}
