
blockquote.md-alert {
  background-color: transparent;
  color: var(--bs-body-color);
  border-left: 3px solid var(--blockquote-line-color);
  margin: 2.5rem 0;
  padding: 1.5rem;
  text-align: left;

  p {
    padding: 0;
    margin: 0;
  }

  &.md-alert-note {
    border-left-color: #0969da;

    > p:first-of-type {
      color: #0969da;
    }
  }

  &.md-alert-tip {
    border-left-color: #1a7f37;

    > p:first-of-type {
      color: #1a7f37;
    }
  }

  &.md-alert-important {
    border-left-color: #8250df;

    > p:first-of-type {
      color: #8250df;
    }
  }

  &.md-alert-warning {
    border-left-color: #bf8700;

    > p:first-of-type {
      color: #bf8700;
    }
  }

  &.md-alert-caution {
    border-left-color: #d1242f;

    > p:first-of-type {
      color: #d1242f;
    }
  }
}
