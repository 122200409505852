
.breadcrumbs {
  font-size: 0.85rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  flex: 1;

  .breadcrumb {
    flex: 0 0 auto;
    display: inline-block;
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 0;

    &:not(:last-child):after {
      content: "›\00a0";
    }

    &:after {
      padding: 0 4px 0 6px;
    }
  }
}
