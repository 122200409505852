/* space-mono-italic - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/space-mono/space-mono-v6-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/space-mono/space-mono-v6-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/space-mono/space-mono-v6-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/space-mono/space-mono-v6-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/space-mono/space-mono-v6-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/space-mono/space-mono-v6-latin-italic.svg#SpaceMono') format('svg'); /* Legacy iOS */
}

/* space-mono-regular - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/space-mono/space-mono-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/space-mono/space-mono-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/space-mono/space-mono-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/space-mono/space-mono-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/space-mono/space-mono-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/space-mono/space-mono-v6-latin-regular.svg#SpaceMono') format('svg'); /* Legacy iOS */
}

/* space-mono-700 - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/space-mono/space-mono-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/space-mono/space-mono-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/space-mono/space-mono-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/space-mono/space-mono-v6-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/space-mono/space-mono-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/space-mono/space-mono-v6-latin-700.svg#SpaceMono') format('svg'); /* Legacy iOS */
}

/* space-mono-700italic - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/space-mono/space-mono-v6-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/fonts/space-mono/space-mono-v6-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/space-mono/space-mono-v6-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/space-mono/space-mono-v6-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('/assets/fonts/space-mono/space-mono-v6-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/space-mono/space-mono-v6-latin-700italic.svg#SpaceMono') format('svg'); /* Legacy iOS */
}
