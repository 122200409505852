$button-main: var(--bs-link-color);
$button-main-hover: #2B83C7;
$button-main-disabled: #C9C9C9;
$button-main-dark-disabled: #F3F3F3;
$button-main-dark-color-disabled: #555;
$button-main-secondary-hover: #D1E5F5;
$button-accent: #308720;
$button-accent-hover: #64C253;
$button-accent-disabled: #C9C9C9;
$button-accent-dark-disabled: #F3F3F3;
$button-accent-dark-color-disabled: #555;
$button-accent-secondary-hover: #BFEEB6;
$button-accent-secondary-hover-color: #308720;
$button-accent-secondary-border: #308720;
$button-accent-secondary-hover-border: #1F6612;

@mixin button-op() {
  padding: 13px 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 30px;
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  min-width: 120px;
  transition: all .3s;
  hyphens: auto;
  text-align: center;
  display: inline-block;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

@mixin button-op-small() {
  min-width: 52px;
  padding: 13px;
}

@mixin button-op-large() {
  min-width: 240px;

  @media all and (max-width: 300px) {
    min-width: 0;
  }
}

@mixin button-op-primary() {
  border-color: $button-main;
  background: $button-main;
  color: $white;

  &:hover, &.hover {
    background-color: $button-main-hover;
    border-color: $button-main-hover;
    color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    background-color: $button-main-disabled;
    border-color: $button-main-disabled;
    box-shadow: none;
  }
}

@mixin button-op-secondary() {
  border-color: $button-main;
  background-color: transparent;
  color: $button-main;

  &:hover, &.hover {
    color: $button-main;
    border-color: $button-main;
    background-color: $button-main-secondary-hover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    border-color: $button-main-disabled;
    color: $button-main-disabled;
    box-shadow: none;
  }
}

@mixin button-op-accent-primary() {
  border-color: $button-accent;
  background: $button-accent;
  color: $white;

  &:hover, &.hover {
    color: $white;
    background-color: $button-accent-hover;
    border-color: $button-accent-hover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    background-color: $button-accent-disabled;
    border-color: $button-accent-disabled;
    box-shadow: none;
  }
}

@mixin button-op-accent-secondary() {
  border-color: $button-accent-secondary-border;
  background-color: transparent;
  color: $button-accent;

  &:hover, &.hover {
    color: $button-accent;
    border-color: $button-accent;
    background-color: $button-accent-secondary-hover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    border-color: $button-accent-disabled;
    color: $button-accent-disabled;
    box-shadow: none;
  }
}

@mixin button-op-dark-primary() {
  border-color: $white;
  background: $white;
  color: $button-main;

  &:hover, &.hover {
    background-color: $button-main-secondary-hover;
    border-color: $button-main-secondary-hover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    color: $button-main-dark-color-disabled;
    background-color: $button-main-dark-disabled;
    border-color: $button-main-dark-disabled;
    box-shadow: none;
  }
}

@mixin button-op-dark-secondary() {
  border-color: $white;
  background-color: transparent;
  color: $white;

  &:hover, &.hover {
    color: $button-main;
    border-color: $button-main;
    background-color: $button-main-secondary-hover;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2);
  }
}

@mixin button-op-dark-accent-primary() {
  border-color: $button-accent;
  background: $button-accent;
  color: $white;

  &:hover, &.hover {
    background-color: $button-accent-hover;
    border-color: $button-accent-hover;
    color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    background-color: $button-accent-disabled;
    border-color: $button-accent-disabled;
    box-shadow: none;
  }
}

@mixin button-op-dark-accent-secondary() {
  border-color: $white;
  background-color: transparent;
  color: $white;

  &:hover, &.hover {
    color: $button-accent-secondary-hover-color;
    border-color: $button-accent-secondary-hover-border;
    background-color: $button-accent-secondary-hover;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2);
  }
}

@mixin inverse-link() {
  color: #fff;

  &:hover {
    color: $blue-300;
  }
}