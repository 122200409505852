@font-face {
  font-family: openproject;
  src: url('/assets/fonts/openproject/openproject.eot');
  src: url('/assets/fonts/openproject/openproject.eot#iefix') format('embedded-opentype'),
  url('/assets/fonts/openproject/openproject.woff2') format('woff2'),
  url('/assets/fonts/openproject/openproject.woff') format('woff'),
  url('/assets/fonts/openproject/openproject.ttf') format('truetype'),
  url('/assets/fonts/openproject/openproject.svg#openproject') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it.
   Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'openproject';
    src: url('../font/openproject.svg?45694247#openproject') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: openproject;
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  margin-left: .2em;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-globe:before { content: '\e800'; } /* '' */
.icon-enterprise:before { content: '\e801'; } /* '' */
.icon-cloud:before { content: '\e802'; } /* '' */
.icon-mail:before { content: '\e803'; } /* '' */
.icon-add:before { content: '\e804'; } /* '' */
.icon-home:before { content: '\e805'; } /* '' */
.icon-ok:before { content: '\e806'; } /* '' */
.icon-ok-circled:before { content: '\e807'; } /* '' */
.icon-plus:before { content: '\e808'; } /* '' */
.icon-minus:before { content: '\e809'; } /* '' */
.icon-down-big:before { content: '\e80a'; } /* '' */
.icon-left-big:before { content: '\e80b'; } /* '' */
.icon-right-big:before { content: '\e80c'; } /* '' */
.icon-up-big:before { content: '\e80d'; } /* '' */
.icon-search:before { content: '\e80e'; } /* '' */
.icon-openproject:before { content: '\e80f'; } /* '' */
.icon-link:before { content: '\e811'; } /* '' */
.icon-help-circled:before { content: '\e812'; } /* '' */
.icon-forums:before { content: '\e813'; } /* '' */
.icon-edit:before { content: '\e814'; } /* '' */
.icon-plus-circled:before { content: '\e815'; } /* '' */
.icon-cancel:before { content: '\e818'; } /* '' */
.icon-copy:before { content: '\e822'; } /* '' */
.icon-glossary:before { content: '\e823'; } /* '' */
.icon-info-filled-circled:before { content: '\e82d'; } /* '' */
.icon-chevron-right:before { content: '\ebbd'; } /* '' */
.icon-email:before { content: '\ebbe'; } /* '' */
.icon-msg:before { content: '\ebbf'; } /* '' */
.icon-phone:before { content: '\ebc0'; } /* '' */
.icon-location:before { content: '\ebc1'; } /* '' */
.icon-mastodon:before { content: '\ebc3'; } /* '' */
.icon-addons:before { content: '\ebc4'; } /* '' */
.icon-rss:before { content: '\ebc5'; } /* '' */
.icon-newsletter:before { content: '\ebc6'; } /* '' */
.icon-twitter:before { content: '\ebc7'; } /* '' */
.icon-info:before { content: '\ebc8'; } /* '' */
.icon-light-bulb:before { content: '\ebc9'; } /* '' */
.icon-alert:before { content: '\ebca'; } /* '' */
.icon-report:before { content: '\ebcb'; } /* '' */
.icon-stop:before { content: '\ebcc'; } /* '' */
.icon-bluesky:before { content: '\ebcd'; } /* '' */
.icon-video-play:before { content: '\ef77'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-github:before { content: '\f09b'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-ion-close-circled:before { content: '\f128'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-bug:before { content: '\f188'; } /* '' */
.icon-translate:before { content: '\f1ab'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-commenting:before { content: '\f27a'; } /* '' */
.icon-reddit-alien:before { content: '\f281'; } /* '' */
.icon-credit-card-alt:before { content: '\f283'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-ion-android-alert:before { content: '\f35b'; } /* '' */
.icon-ion-lock-combination:before { content: '\f4d4'; } /* '' */
