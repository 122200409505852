.sidebar-wrapper {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding-top: $header-mobile-height;
  }
}

.sidebar-nav-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  transition: all .3s;
  background-color: var(--bs-secondary-bg);
  border-right: 1px solid var(--bs-border-color);
  z-index: 2;
  width: 20%;
  overflow-y: auto;

  @include media-breakpoint-down(lg) {
    width: 30%;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    height: auto;
    display: none;

    &.show {
      position: fixed;
      top: $header-mobile-height * 2;
      left: 0;
      right: 0;
      display: block;
      max-height: 100%;
      overflow: auto;
      background-color: var(--bs-body-bg);
      padding: 0;
      box-shadow: 0 5px 5px rgba(0, 0, 0, .15);
    }
  }
}

.sidebar-nav {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}

.sidebar-nav-content {
  top: $header-height;
  padding-top: 13px;

  @include media-breakpoint-down(md) {
    position: fixed;
    top: $header-mobile-height * 2;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
    background-color: var(--bs-body-bg);
    padding: 0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .15);
    z-index: 20;
  }

  &.show {
    display: block;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }


  .sidebar-nav-entry-link {
    line-height: 1.25;
    font-size: 14px;
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 7px;

    i {
      margin-left: 2px;
      font-size: 0.8em;
    }

    &:hover {
      color: var(--sidebar-hover-color);
    }
  }

  .sidebar-nav-collapse-toggle {
    cursor: pointer;
    float: right;
    width: 50px;
    height: 36px;
    display: flex;
    background-color: transparent;
    border: unset;
    align-items: center;
    justify-content: center;
    border-left: none !important;

    &:after {
      content: "";
      border: solid $gray-700;
      border-width: 0 1px 1px 0;
      display: inline-block;
      transform: rotate(-135deg);
      transition-duration: 0.3s;
      transition-property: transform;
      width: 7px;
      height: 7px;
    }

    &.collapsed {
      &:after {
        transform: rotate(45deg);
      }
    }

    &:hover {
      color: var(--sidebar-hover-color);
      background-color: var(--sidebar-bg-hover-color);
    }
  }

  .sidebar-nav-entry {
    padding: 0;
    display: flex;
    justify-content: space-between;

    &:hover {
      color: var(--sidebar-hover-color);
      background-color: var(--sidebar-bg-hover-color);
    }

    a {
      color: var(--bs-body-color);
    }

    &.level-0 {
      font-weight: 600;
      margin-bottom: 3px;
      margin-top: 3px;

      a {
        text-decoration: none;
        padding-left: 18px;
      }

      &.current a {
        padding-left: 14px;
      }
    }

    &.level-1 {
      a {
        padding-left: 20px;
      }

      &.current a {
        padding-left: 16px;
      }
    }

    &.level-2 {
      a {
        padding-left: 38px;
      }

      &.current a {
        padding-left: 34px;
      }
    }

    &.level-3 {
      a {
        padding-left: 56px;
      }

      &.current a {
        padding-left: 52px;
      }
    }

    &.current {
      background-color: var(--sidebar-active-bg-color);

      a {
        font-weight: 600;
        border-left: 4px solid $primary;
        color: var(--sidebar-active-color);

        &:visited, &:hover {
          color: var(--sidebar-active-color);
        }
      }
    }

  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 70px;
  }
}
