.logo-carousel {
  margin-top: 75px;
  margin-bottom: 75px;

  .logo-carousel-title {
    text-align: center;
    font-size: 17px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 3px;
    margin-bottom: 1.5em;
  }

  .logo-carousel-items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;

    .logo-carousel-logo {
      display: flex;
      flex: 1;
      transition: all 0.3s ease;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      align-items: center;
      justify-content: center;

      img {
        height: auto;
        width: 100%;
        min-width: 100px;
        max-width: 150px;
        object-fit: contain;
        min-height: 60px;
        transition: all 0.3s ease;
      }
    }
  }

  &.logo-carousel-grey-effect .logo-carousel-items {
    .logo-carousel-logo {
      img {
        filter: var(--image-greyscale);

        &:hover {
          filter: var(--image-greyscale-hover);
          background-color: var(--logo-background);
        }
      }
    }
  }

  .logo-carousel-button {
    text-align: center;
    margin-top: 20px;
  }

  @include media-breakpoint-down(xxl) {
    .logo-carousel-items {
      .logo-carousel-logo {
        flex: 25%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .logo-carousel-title {
      font-size: 12px;
    }

    .logo-carousel-items {
      .logo-carousel-logo {
        flex: 50%;
      }
    }
  }
}

