@use "sass:map";

.footer-shape {
  width: 100%;
  position: relative;
  margin-top: -50px;
  pointer-events: none;

  svg.footer-line {
    width: 100%;
    height: auto;
    pointer-events: none;
  }
}

footer {
  font-size: .8rem;
  background-color: var(--footer-bg);
  background-image: var(--footer-gradient-bg);
  color: var(--footer-color);
  display: flex;
  flex-direction: column;
  z-index: 8;
  padding: 0 50px;
  margin-top: -1px;

  .footer__wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: map.get($container-max-widths, fourk);
    padding-left: 0;
    padding-right: 0;
  }

  .footer__top {
    padding-top: 2rem;

    .breadcrumbs .breadcrumb {
      max-width: 100%;

      a {
        color: var(--footer-color);

        &:hover {
          color: var(--footer-link-hover-color);
        }
      }

      &.active a {
        color: var(--footer-color);
      }
    }
  }

  .footer__mid {
    border-bottom: 1px solid #11588a;
    border-top: 1px solid #11588a;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .footer__bottom {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 0 1rem;

    .footer__menu--legal {
      ul {
        flex-wrap: wrap;
        text-align: center;

        li {
          padding: 0 1rem 0 0;
        }

        a {
          color: var(--footer-color);
          line-height: 1.5rem;
          text-decoration: none;

          &:hover {
            color: var(--footer-link-hover-color);
          }
        }
      }
    }

    .footer__copyright {
      text-align: right;
      display: flex;
      color: var(--footer-color);

      .footer__copyright--inner {
        flex: 1;
      }
    }

    .footer__menu--social {
      flex: 1;
      display: flex;
      justify-content: center;
      padding-left: 10px;

      .nav {
        flex-wrap: nowrap;
      }

      li {
        margin-right: 10px;

        a {
          height: 30px;
          width: 30px;
          border-radius: $border-radius;
          color: var(--footer-color);
          line-height: 1.5rem;
          text-decoration: none;
          background: #0a3d5e;
          display: flex;
          align-items: center;

          i {
            font-size: 20px;
          }

          &:hover {
            color: var(--footer-link-hover-color);
            background: #0d4a73;
          }
        }
      }
    }
  }

  .footer__navigation {
    display: flex;
    flex-wrap: wrap;
    padding: 2.5rem 0 0;
    justify-content: space-between;

    ul {
      list-style-type: none;
      padding: 1rem 0;
    }

    > li {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 0;
      margin-bottom: 1.5rem;

      a {
        text-transform: uppercase;
        color: var(--footer-color);
        font-family: $font-family-monospace;
        letter-spacing: 1px;
        font-weight: $link-font-weight;
        font-size: .8125rem;
        text-decoration: none;

        &:hover {
          color: var(--footer-link-hover-color);
        }
      }

      li a {
        text-transform: inherit;
        font-family: inherit;
        color: var(--footer-color);
        letter-spacing: inherit;
        font-weight: 300;
        font-size: .8rem;
        line-height: 1.75rem;

        &:hover {
          color: var(--footer-link-hover-color);
        }
      }

      .nav-link-badge {
        color: #fff;
        font-size: 0.7em;
        margin-left: 10px;
        font-weight: bold;
        border-radius: 100px;
        border: 2px solid #fff;
        vertical-align: text-bottom;
        padding: 2px 7px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
    }
  }

  &.footer-minimal {
    .footer__bottom {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-down(xl) {
    .footer__navigation > li {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0 30px;

    .footer__navigation > li {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .footer__navigation li ul li {
      margin-bottom: 1em;

      a {
        line-height: 2em;
      }
    }

    .footer__bottom {
      .footer__copyright, .footer__menu--social, .footer__menu--legal {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
      }

      .footer__menu--legal .nav {
        display: inline-flex;
      }

      .footer__menu--social {
        margin-top: 10px;
        margin-bottom: 10px;

        .nav {
          display: inline-flex;
          flex-wrap: wrap;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .footer__copyright {
        flex-wrap: wrap;

        .footer__copyright--inner {
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center;
        }

      }
    }
  }

  @include media-breakpoint-down(sm) {
    .footer__navigation > li {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
