@mixin shape-top-padding() {
  padding-top: 300px;

  @include media-breakpoint-down(uxxl) {
    padding-top: 250px;
  }

  @include media-breakpoint-down(xxl) {
    padding-top: 200px;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 150px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 100px;
  }
}
