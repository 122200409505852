.markdown {
  h1, h2 {
    padding-bottom: 1.25rem;
  }

  p {
    margin-bottom: 2rem;
  }

  ul, ol {
    margin: 1.5rem 0 2.5rem;

    li {
      line-height: 1.5rem;
      font-size: 1.125rem;

      p {
        display: inline;
      }
    }
  }

  ul {
    li {
      list-style: none;

      &:before {
        content: "";
        border-color: transparent $primary;
        border-style: solid;
        border-width: 0.30rem 0 0.30rem 0.40rem;
        display: block;
        height: 0;
        width: 0;
        left: -1.25rem;
        top: 0.95rem;
        position: relative;
      }
    }
  }

  ol {
    padding-left: 2rem;
    list-style-type: none;

    li {
      counter-increment: step-counter;

      &:before {
        content: counter(step-counter) ".";
        margin-right: 0.75rem;
        color: $primary;
        position: relative;
        display: inline-block;
        margin-left: -26px;
      }

      ol {
        li {
          counter-increment: step;

          &:before {
            content: counter(step, lower-roman) ".";
            margin-right: 0.75rem;
            color: $primary;
            position: relative;
            display: inline-block;
            margin-left: -26px;
          }
        }
      }

      ul {
        li {
          counter-increment: inherit;

          &:before {
            content: " ";
            display: block;
            margin: 0;
          }

        }

      }
    }
  }

  blockquote {
    background-color: var(--blockquote-bg-color);
    color: var(--blockquote-color);
    border-left: 3px solid var(--blockquote-line-color);
    margin: 2.5rem 0;
    padding: 1.5rem;
    text-align: justify;

    p {
      margin-bottom: 0;
    }
  }

  @import "markdown_alerts";

  blockquote.box {
    text-align: center;
    border: 3px solid var(--bs-secondary-bg);
    background-color: var(--bs-secondary-bg);
    padding-top: 2rem;
    padding-bottom: 3rem;

    h1 {
      padding-bottom: 0;
    }
  }

  video, img {
    max-width: 100%;
  }

  table {
    margin-bottom: 50px;

    thead {
      background: $table-header-bg;

      tr {
        th {
          padding: 5px 10px;
        }
      }
    }

    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid #ddd;
      }
    }
  }
}
