.search-head {
  margin-bottom: 40px;
  position: relative;
  background-color: transparent;

  button.header-search-toggle {
    background-color: transparent;
    border-color: transparent;
    border-radius: 50%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &:after {
    background-image: var(--my-background-var);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: ' ';
    position: absolute;
    pointer-events: none;
    top: -80px;
    bottom: -120px;
    right: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0 0 18px 18px var(--bs-body-bg) inset;
  }

  .search-tabs-toggle {
    background-color: transparent;
    border: none;
  }

  .search-tabs-toggle, .search-tabs-label {
    display: none;
  }

  .tab-selection {
    justify-content: flex-start;
  }

  .search-head-wrapper {
    margin-top: 8px;
    position: relative;
  }

  .search-box {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: rgba(0, 112, 186, 0.059);
    border-radius: $border-radius;

    .search-field {
      width: 100%;
      height: 50px;
      position: relative;

      .search-input {
        width: 100%;
        height: 50px;
        font-size: 24px;
        padding: 10px 48px 10px 20px;
        background-color: var(--bs-body-bg);
        border-color: var(--form-input-border-color);
      }

      .icon-search {
        position: absolute;
        top: 10px;
        right: 18px;
        font-size: 20px;
        color: #5e5e5e;
        cursor: pointer;
      }
    }

    .search-about-tools {
      text-align: right;
      font-size: 0.8em;
      padding-top: 6px;

      a {
        padding: 0 4px;
      }

    }

    @include media-breakpoint-down(md) {
      margin-left: -20px;
      margin-right: -15px;

      .search-field {
        .icon-search {
          font-size: 17px;
          top: 12px;
          right: 10px;
        }

        .search-input {
          padding: 10px 38px 10px 10px;
          font-size: 18px;
        }
      }
    }
  }

  .search-about {
    background-color: rgba(255, 255, 255, 0.7);

    > div {
      padding: 20px 10px;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #9fa3b1;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: #9fa3b1;
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: #9fa3b1;
  }

  @include media-breakpoint-down(md) {
    .search-head-wrapper {
      position: relative;
    }

    .search-tabs-wrapper {
      background-color: var(--bs-body-bg);
      border: var(--bs-border-width) solid var(--form-input-border-color);
      min-height: 40px;
      margin-right: 4px;
    }

    .search-tabs-label {
      display: block;
      font-family: $font-family-monospace;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: center;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .search-sections-tabs {
      align-items: flex-start;
      display: flex;
      width: 100%;
      flex-direction: column;
      position: absolute;
      padding-top: 3px;

      .nav-item {
        margin: 0;
        width: 100%;

        .nav-link {
          margin-left: 5px;
          margin-right: 5px;
          padding-top: 10px;
          padding-bottom: 10px;
          background-size: 0;
          width: 100%;
          text-align: start;
          color: var(--bs-body-color);
        }

        .nav-link.active {
          color: var(--bs-body-color);
        }

        .nav-link:not(.active) {
          display: none;
        }
      }
    }

    .search-tabs-toggle {
      display: block;
      position: absolute;
      right: 0;
      height: 40px;
      width: 40px;
    }

    .search-tabs-open {

      .search-tabs-toggle {
        i:before {
          transform: rotate(180deg);
        }
      }

      .search-sections-tabs {
        z-index: 3000;
        width: unset;
        left: 0;
        right: 40px;
        background-color: var(--bs-body-bg);
        border: var(--bs-border-width) solid rgba(0, 112, 186, .32);
        border-top-width: 0;

        .nav-item {
          border-bottom: 1px solid var(--bs-border-color);

          &:last-of-type {
            border-bottom: none;
          }

          .nav-link, .nav-link.active {
            display: block;
          }

          &:hover {
            .nav-link {
              color: var(--dropdown-link-active-color);
            }
          }
        }
      }
    }
  }
}
