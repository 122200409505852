.columns-carousel {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  .columns-carousel-header {
    text-align: center;

    h2 {
      margin-bottom: 20px;
    }
  }

  .carousel {
    padding: 0;
    max-width: 96%;
    margin: 50px auto;
  }

  .carousel-row {
    display: flex;
    flex-wrap: nowrap;
  }

  .carousel-image {
    flex: 50%;
    padding-right: 5px;
  }

  .carousel-text {
    flex: 50%;
    padding: 0 40px 0 5px;

    h3 {
      font-size: 1.5rem;
      line-height: 2.35rem;
      padding-bottom: 1.25rem;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .carousel-control-prev {
    justify-content: flex-start;
    margin-left: -30px;
  }

  .carousel-control-next {
    justify-content: flex-end;
    margin-right: -30px;
  }

  .carousel-control-prev-icon.carousel-control-prev-icon-dark {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
  }

  .carousel-control-next-icon.carousel-control-next-icon-dark {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }

  .carousel-indicators {
    z-index: 0;
    bottom: -75px;
    list-style-type: none;

    li {
      background-color: #aaa;
      border-radius: 50%;
      height: 16px;
      width: 16px;

      &.active {
        background-color: $link-color-dark;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .carousel-row {
      flex-direction: column;

      .carousel-text {
        padding: 20px 20px 0;
        order: 2;
      }

      .carousel-image {
        order: 1;
      }
    }

    .carousel-control-prev {
      margin-left: -25px;
    }

    .carousel-control-next {
      margin-right: -25px;
    }
  }

}
