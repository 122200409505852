.newsletter-section {
  padding: 100px 0;

  img {
    width: 100%;
    max-width: 130px;
    height: auto;
    display: block;
    margin: 0 auto 40px;
  }

  h2 {
    margin-bottom: 40px;
  }

  ul {
    margin-bottom: 40px;
  }
}
