.topics {
  padding-left: 0;
  padding-right: 0;

  .main-topics {
    position: relative;
    margin: 80px auto 40px;
    padding: 0 50px;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

    li {
      display: block;
      flex: 1 0 270px;

      .topic--wrapper {
        padding: 40px 30px;
        text-align: center;
        display: flex;
        flex-grow: 1;
        position: relative;
        flex-direction: column;
        text-decoration: none;

        &:visited {
          text-decoration: none;
        }

        p {
          vertical-align: bottom;
        }

        img {
          height: 110px;
          margin: 20px auto 5px;
          transition: .2s cubic-bezier(0, 0, 0, 1);
          box-shadow: none;
          display: block;
        }

        .topic--badge {
          position: absolute;
          top: 10px;
          left: 0;
          padding: 5px 10px;
          border-radius: $border-radius;
          font-weight: 400;
          font-family: $font-family-monospace;

          &.-gray {
            background: #6b6b6b;
            color: $white;
          }

          &.-red {
            background: #864659;
            color: $white;
          }

          &.-green {
            background: #1f8326;
            color: $white;
          }
        }

        .topic--header {
          text-align: center;
          font-weight: 400;
          margin-top: 20px;
          font-size: 1.2rem;
        }

        .topic--text {
          font-size: 16px;
          line-height: 1.2em;
        }
      }
    }

    @include media-breakpoint-down(xl) {
      padding: 0;
    }

    @include media-breakpoint-down(lg) {
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;
      grid-template-columns: repeat(1, 1fr);

      li {
        width: 100%;
        min-width: auto;
        flex: initial;
        margin: 20px 0 0;

        .topic--wrapper {
          padding: 30px;
        }
      }
    }
  }

  h2 {
    margin-top: 60px;
    margin-bottom: 40px;
    border-bottom: none;
    text-align: center;
    font-weight: 900;
  }

  .popular-topics {
    width: 100%;
    padding: 0 15px 80px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      grid-gap: 15px;
    }

    li {
      display: block;
      flex: 1;
      transition: all 350ms ease-in-out;

      .popular-topic--wrapper {
        color: var(--body-color);
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        @include media-breakpoint-down(xl) {
          flex-direction: column;
          align-items: center;
        }

        img {
          height: 100px;
          padding-bottom: 20px;
        }

        .topic--content {
          padding-left: 20px;
        }

        &:hover {
          color: var(--bs-link-hover-color);
          text-decoration: none;
        }

        .topic--header {
          display: inline-block;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
      }
    }

  }

}