@use "sass:map";

.faq-accordion-section {
  background-color: #13316f;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  @include shape-top-padding;

  .faq-shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    top: -1px;

    svg, img {
      width: 100%;
      height: auto;
      display: block;
      position: relative;
    }
  }

  .collapsing {
    transition: none;
    display: none;
  }

  .faq-accordion-container {
    margin: 0 auto 150px;
    max-width: map.get($container-max-widths, xl);

    h2 {
      color: #fff;
      text-align: center;
      margin-bottom: 75px;
      font-weight: 700;
      font-size: 2.125rem;
    }

    .faq-accordion {
      padding-left: 30px;
      padding-right: 30px;
      margin: 0 auto;
    }

    .accordion-item {
      margin: 10px;
      background-color: transparent;
      border: none;
    }

    .accordion-header {
      padding: 0 10px;
      background-color: transparent;

      .accordion-button {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: $border-radius;
        font-weight: 600;
        padding-top: 25px;
        padding-bottom: 25px;
        margin: 0;
        font-size: 16px;
        color: #fff;

        &:after {
          --bs-accordion-btn-icon: none;
          --bs-accordion-btn-active-icon: none;

          font-family: $font-family-icon;
          font-size: 12px;
          text-align: center;
          color: #fff;
          content: '\ebbd';
          transform: rotate(-90deg);
          transition-duration: 0.3s;
          transition-property: transform;
        }

        &.collapsed {
          background-color: rgba(255, 255, 255, 0.1);

          &:after {
            transform: rotate(90deg);
          }
        }
      }
    }

    .accordion-collapse {
      background-color: transparent;
      padding: 20px;
      color: #fff;

      a {
        color: #fff;
        font-weight: bold;
      }

      p {
        margin: 0;
      }
    }
  }

  .faq-accordion-button {
    text-align: center;
    margin-top: 36px;

    a {
      color: #fff;
    }
  }

  @include media-breakpoint-down(xl) {
    .faq-accordion-head {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @include media-breakpoint-down(sm) {

    .accordion-body {
      padding-right: 15px;
      padding-left: 15px;
    }

    .faq-accordion-container {
      margin-bottom: 100px;

      .faq-accordion {
        padding-right: 0;
        padding-left: 0;
        margin: 0 auto;
      }
    }
  }
}