.op-form {
  max-width: 900px;
  margin: 0 auto;
  position: relative;

  select {
    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat !important;
    background-position-x: 100% !important;
    background-position-y: 50% !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
  }

  select::-ms-expand {
    display: none;
  }

  label {
    font-weight: 400;
    font-size: 0.95em;
  }

  .required:after {
    color: var(--bs-body-color);
    content: "*";
    position: absolute;
    margin-left: 3px;
  }

  input, select, textarea {
    background-color: var(--form-input-bg);
    border: 1px solid var(--form-input-border-color);
  }

  .form-check-input:checked {
    background-color: var(--form-input-bg-checked);
  }

  .form-check + .form-check {
    margin-top: 0;
  }

  button {
    margin-top: 2em;
  }

  .error-message {
    margin-top: 20px;
    display: none;
  }

  .success-message {
    display: none;
    flex-direction: column;
    padding: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    border: 1px solid #cbd6e2;
    background: #fff;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;

    i {
      color: $green;
      font-size: 4em;
      position: relative !important;
      vertical-align: middle;
      display: block;
    }
  }

  button[type="submit"] {
    min-height: 3.5em;

    .text {
      display: inline-block;
    }

    .loader {
      display: none;
    }
  }

  &.processing {
    button[type="submit"] {
      .text {
        display: none;
      }

      .loader {
        display: inline-block;
      }
    }
  }
}

