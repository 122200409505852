@use "sass:color";

// Neutral palette (overwrite bootstrap variables)
$gray-100: #f2f2f2;
$gray-200: #dfdfdf;
$gray-300: #ccc;
$gray-400: #bababa;
$gray-500: #a7a7a7;
$gray-600: #919191;
$gray-700: #707070;
$gray-800: #555;
$gray-900: #2e2e2e;
$gray-950: #1f1f1f;

// Blue palette  (overwrite bootstrap variables)
$blue-50: #f4f9fb;
$blue-100: #e4f0fb;
$blue-200: #b8d6f4;
$blue-300: #a1d6f9;
$blue-400: #418cd8;
$blue-500: #1f78d1;
$blue-600: #1b69b6;
$blue-700: #17599c;
$blue-800: #134a81;
$blue-900: #114172;

// Bootstrap5 Variables color mode type

$color-mode-type: data;

// $color-mode-type: media-query;

// Bootstrap5 Variables overwrite custom breakpoints
$grid-breakpoints: (
        xs: 0, // Extra small screen / phone
        sm: 576px, // Small screen / phone
        md: 768px, // Medium screen / tablet
        lg: 992px, // Large screen / desktop
        xl: 1200px, // Extra large screen / wide desktop
        xxl: 1400px, // Even larger screen
        uxxl: 2000px, // Ultra larger screen
        fourk: 3800px // 4K
);

// Bootstrap5 Variables overwrite custom breakpoints
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
        uxxl: 1600px,
        fourk: 1920px
);

// Header
$header-height: 75px;
$header-mobile-height: 55px;
$header-switch-mobile-breakpoint: 1025px;

// Body
$body-bg: #fff;
$body-color: $gray-950;
$body-bg-dark: #0f1318;
$body-secondary-bg: #ebf3f7;
$body-secondary-bg-dark: #192128;
$font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: "Space Mono", "Menlo", "DejaVu Sans Mono", "Liberation Mono", "Consolas", "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace;
$font-family-icon: openproject;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$enable-smooth-scroll: true;
$border-radius: 3px;
$breadcrumb-bg: color.adjust(#fff, $lightness: -2%);
$pre-code-bg: #000;
$pre-code-color: #9CDC70;
$code-color: #57733F;
$kbd-bg: rgba(95, 131, 65, .1);
$dropdown-border-color: $gray-200;
$border-color: $gray-200;
$table-border-color: $gray-200;
$border-color-dark: $gray-800;
$dropdown-dark-border-color: $gray-800;
$table-border-color-dark: $gray-800;
$primary: #1a67a3;
$link-color: #1a67a3;
$link-hover-color: #134a81;
$link-color-dark: #2f81f7;
$link-font-weight: 400;
$link-decoration: none;
$accordion-button-icon: none;
$accordion-button-active-icon: none;
$popover-max-width: 600px;
$component-active-bg: $blue-50;
$dropdown-link-active-color: $primary;
$dropdown-link-active-color-dark: #5693f1;
$form-select-bg: #ebf3f7;
$form-select-border-color: #cbd6e2;
$form-select-bg-dark: #101921;
$body-secondary-color: #525252;
$body-secondary-color-dark: #ececec;

// end of bootstrap overrides

$table-header-bg: $blue-50;
$header-section-headline: #005085;
$header-section-headline-dark: #5693f1;
$header-section-title: #414d61;
$header-section-title-dark: #a3b5d5;
