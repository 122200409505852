.search-sections {

  .search-section {
    display: none;
  }

  .search-section-head {

    .search-result-count {
      font-size: 14px;
    }
  }

  .search-section-loader {
    display: none;
    color: #848484;
    font-size: 1.5em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 110px;

    &.active {
      display: flex;
    }

    .circular {
      animation: rotate 2s linear infinite;
      height: 100px;
      position: relative;
      width: 100px;
    }

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke: #B6463A;
      animation: dash 1.5s ease-in-out infinite,
      color 6s ease-in-out infinite;
      stroke-linecap: round;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }

      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }

    @keyframes color {
      100%, 0% {
        stroke: #d62d20;
      }

      40% {
        stroke: #0057e7;
      }

      66% {
        stroke: #008744;
      }

      80%, 90% {
        stroke: #ffa700;
      }
    }
  }

  .search-section-results {
    padding-left: 0;
    list-style: none;

    li {
      border-bottom: 1px solid var(--bs-border-color);
      padding-top: 18px;
      padding-bottom: 20px;
      display: block;

      &:first-of-type {
        border-top: 1px solid var(--bs-border-color);
      }

      &.no-result {
        font-weight: bold;
        font-size: 0.9em;
      }
    }

    .result-score {
      float: right;
      font-size: 0.8em;
    }

    .badge {
      margin-left: 12px;
      font-size: 11px;
      line-height: 1.2em;
    }

    .result-title {
      font-weight: 500;
      font-size: 1.2em;
      line-height: 1.1em;
      display: flex;
    }

    .result-preview {
      margin-top: 10px;
      position: relative;
      overflow: hidden;
      transition: max-height 0.15s ease-out;
      border: 1px solid transparent;

      p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.4em;
        color: var(--bs-secondary-color);

        &.expanded-only {
          display: none;
        }
      }

      .toggle-preview {
        float: right;
        height: 30px;
        width: 30px;
        text-align: center;
        border-radius: $border-radius;
        border: 1px solid transparent;
        transition: all .3s ease-in-out;

        &:after {
          font-family: $font-family-icon;
          font-size: 16px;
          content: '\f107';
        }

        &:hover {
          background-color: var(--bs-primary-bg-subtle);
          border-color: var(--bs-border-color);
        }
      }

      &.expanded {
        p.expanded-only {
          display: block;
        }

        .toggle-preview {
          background-color: var(--bs-primary-bg-subtle);
          border-color: var(--bs-border-color);

          &:after {
            content: '\f106';
          }
        }
      }
    }

    .result-url {
      font-size: 1em;
      padding-bottom: 8px;
      color: green;
    }
  }

  .search-section-pagination {
    text-align: center;

    .page-button {
      background-color: transparent;
      padding: 12px;
      text-align: center;
      margin-right: 4px;
      border-radius: $border-radius;
      border: 1px solid transparent;
      font-size: 12px;

      &.active {
        font-weight: bold;
        background-color: var(--bs-primary-bg-subtle);
        border-color: var(--bs-border-color);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .result-preview {
      margin-top: 0;
    }

    .search-section-results {
      .show-more-toggle {
        float: none;
      }
    }
  }
}
