.button-op {
  @include button-op;
}

.button-op-small {
  @include button-op-small;
}

.button-op-large {
  @include button-op-large;
}

.button-op-round-off-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.button-op-round-off-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.button-op-primary {
  @include button-op-primary;
}

.button-op-secondary {
  @include button-op-secondary;
}

.button-op-accent-primary {
  @include button-op-accent-primary;
}

.button-op-accent-secondary {
  @include button-op-accent-secondary;
}

.button-op-dark-primary {
  @include button-op-dark-primary;
}

.button-op-dark-secondary {
  @include button-op-dark-secondary;
}

.button-op-dark-accent-primary {
  @include button-op-dark-accent-primary;
}

.button-op-dark-accent-secondary {
  @include button-op-dark-accent-secondary;
}
