@mixin default-hero-h1() {
  font-size: 3.5em;
  font-weight: 900;
  line-height: 1.1em;
  padding: 0;

  @include media-breakpoint-down(md) {
    font-size: 2.5em;
    line-height: 1em;
  }

  @include media-breakpoint-only(xs) {
    font-size: 2em;
  }
}

@mixin default-hero-h2() {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.85rem;
  padding: 0;

  @include media-breakpoint-down(md) {
    line-height: 1.5em;
  }
}

.default-hero {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;

  .standard-background-image {
    background-color: #34373a;
  }

  .content {
    padding: 140px 30px;
    position: relative;
    display: block;
    color: #fff;
    text-align: center;
    margin: 0 auto;

    h1 {
      margin: 0 auto 20px;
      max-width: 800px;

      @include default-hero-h1
    }

    h2 {
      @include default-hero-h2
    }

    p {
      color: $white;
      margin: 0 auto 2rem;
      max-width: 800px;
    }

    .default-hero-link {
      font-weight: 500;

      @include inverse-link;

      i {
        font-size: 0.75em;
        margin-left: 6px;
      }
    }
  }

  .default-hero-image {
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 50px;

    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 170px;
      margin: 0 auto;
    }
  }

  .default-hero-image-circled {
    border: 1px solid #fff;
    display: inline-flex;
    border-radius: 55px;
    height: 105px;
    width: 105px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 50px;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      width: 75px;
    }
  }

  .hero-trial-button {
    margin: 60px auto 0;
  }

  .slogans {
    display: flex;
    padding: 0;
    margin: 30px 0 0;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;

    li {
      flex: 0 0 auto;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      padding: 10px;
    }

    i {
      color: #35c53f;
      font-size: 23px;
      margin-right: 4px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;

      li {
        padding: 0;
        font-size: 14px;
      }

      i {
        font-size: 16px;
        margin-right: 0;
      }
    }
  }

  .category {
    font-family: $font-family-monospace;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
    line-height: 1.3em;
    padding: 0;
  }

  &.default-hero-white {
    background-color: var(--bs-body-bg);

    .content, .content p, .content a:not(.button-op) {
      color: var(--bs-body-color);
    }
  }

  .default-hero-shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    bottom: 0;
    pointer-events: none;

    svg, img {
      width: 100%;
      height: auto;
      display: block;
      position: relative;
    }

    svg {
      .default-hero-shape-path-1 {
        fill: var(--wave-shape-color);
        stroke: var(--wave-shape-color);
      }

      .default-hero-shape-path-2 {
        fill: var(--bs-body-bg);
        stroke: var(--bs-body-bg);
      }
    }

    &.default-hero-shape-white {
      svg {
        .default-hero-shape-path-1 {
          fill: var(--wave-shape-color-white);
          stroke: var(--wave-shape-color-white);
        }

        .default-hero-shape-path-2 {
          fill: var(--bs-secondary-bg);
          stroke: var(--bs-secondary-bg);
        }
      }
    }
  }

  &.default-hero-large {
    .content {
      padding: 200px 30px 180px;
    }
  }

  &.with-shape {
    margin-bottom: 40px;

    .content {
      padding-top: 8%; // offset transparent header until breakpoint-lg with a smaller padding-top
      padding-bottom: 12%;

      @include media-breakpoint-down(xxl) {
        padding-top: 10%;
        padding-bottom: 12%;
      }

      @include media-breakpoint-down(xl) {
        padding-top: 12%;
        padding-bottom: 14%;
      }

      @include media-breakpoint-down(lg) {
        padding-top: 16%;
        padding-bottom: 15%;
      }

      @include media-breakpoint-down(md) {
        padding-top: 15%;
        padding-bottom: 12%;
      }

      @include media-breakpoint-only(xs) {
        padding-top: 120px;
        padding-bottom: 80px;
      }
    }
  }

  &.with-shape.default-hero-large {
    .content {
      margin-top: 93px;
      margin-bottom: 93px;

      @include media-breakpoint-down(sm) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.debug-responsive .default-hero {
  h1 {
    color: red;
  }

  @include media-breakpoint-down(fourk) {
    h1 {
      color: steelblue;
    }
  }

  @include media-breakpoint-down(uxxl) {
    h1 {
      color: indigo;
    }
  }

  @include media-breakpoint-down(xxxl) {
    h1 {
      color: chocolate;
    }
  }

  @include media-breakpoint-down(xxl) {
    h1 {
      color: yellow;
    }
  }

  @include media-breakpoint-down(xl) {
    h1 {
      color: green;
    }
  }

  @include media-breakpoint-down(lg) {
    h1 {
      color: blue;
    }
  }

  @include media-breakpoint-down(md) {
    h1 {
      color: magenta;
    }
  }

  @include media-breakpoint-only(xs) {
    h1 {
      color: darkcyan;
    }
  }
}

.default-hero-h1 {
  @include default-hero-h1
}

.default-hero-h2 {
  @include default-hero-h2
}

.default-hero-section {
  .content p {
    color: #fff;
  }

  h2:not(.h1) {
    font-size: 2.125rem !important;
    line-height: 2.3rem !important;
    font-weight: 900 !important;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .content {
      padding: 80px 30px;
    }
  }

}

