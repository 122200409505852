.icon-addons {
  &.plan-bas {
    color: #FB8F44;
  }

  &.plan-pro {
    color: #BF3989;
  }

  &.plan-pre {
    color: #4AC26B;
  }

  &.plan-cor {
    color: #54AEFF;
  }
}
