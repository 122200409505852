span.screenshot-border {
  border-style: solid;
  border-width: 30px;
  border-color: rgb(255, 255, 255, .3);
  filter: drop-shadow(0 0 40px rgba(0, 112, 186, .3));
  position: relative;
  border-radius: 30px;
  display: block;
  width: fit-content;
  margin: 50px auto;

  @include media-breakpoint-down(md) {
    border-width: 10px;
    overflow: hidden;
  }

  img.image-tabs, img.image-screenshot {
    box-shadow: 0 0 100px 0 rgb(0, 179, 255, 0.1);
    vertical-align: top;
  }

  &:before {
    content: "";
    background-image: url("/assets/images/art/svgblur-bg-light-16-9.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 0.5;
    position: absolute;
    display: inline-block;
    pointer-events: none;
    top: -30%;
    left: -30%;
    width: 160%;
    height: 160%;
    z-index: -1;
  }
}

span.tabs-border {
  margin-top: 50px;
  border-width: 30px 0 0;
  border-color: rgb(255, 255, 255, 0.3);
  border-radius: 30px;
  filter: drop-shadow(0 0 40px rgba(0, 112, 186, .3));
  display: block;
  width: fit-content;

  img.tabs-header {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  @include media-breakpoint-down(md) {
    overflow: hidden;
  }

  span.screenshot-border {
    margin-top: 0;
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    filter: unset;
  }
}

@include color-mode(dark) {
  span.screenshot-border {
    border-color: rgb(0, 0, 0, .3);
    filter: drop-shadow(0 0 40px rgba(0, 45, 70, 0.3));

    img.image-tabs, img.image-screenshot {
      box-shadow: 0 0 100px 0 rgba(1, 52, 77, 0.1);
    }
  }
}
