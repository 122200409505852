.feature-grid {

  h2 {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 50px;
  }

  .feature-grid-content {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;

    .feature-item {
      flex: 33%;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 40px;

      .feature-item-image {
        img {
          width: 50%;
          height: auto;
          margin: 0 auto;
          display: block;
          max-width: 160px;
        }
      }

      h3 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: 1.5em;
      }

      .feature-item-text {
        flex: 1;
        display: flex;
        flex-direction: column;

        p:first-of-type {
          flex: 1;
        }
      }
    }

    &.feature-grid-4 {
      .feature-item {
        flex: 25%;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .feature-grid-content {
      .feature-item, &.feature-grid-4 .feature-item {
        flex: 50%;
        padding-bottom: 20px;
      }
    }
  }

  @include media-breakpoint-between(sm, md) {
    .feature-grid-content {
      .feature-item, &.feature-grid-4 .feature-item {
        flex: 100%;
        flex-direction: row;
        margin-top: 1.5rem;

        .feature-item-text {
          padding-left: 1.5rem;

          h3 {
            margin-top: 0;
          }
        }

        .feature-item-image {
          img {
            width: 100px;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    .feature-grid-content {
      .feature-item, &.feature-grid-4 .feature-item {
        flex: 100%;
      }
    }
  }

}
