@import "../base/global";

.learn-more-link {
  padding: 16px 8px 16px 0;
  font-size: 14px;
  font-weight: bold;
  color: var(--bs-link-color);
  text-transform: uppercase;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  transition: all .3s;

  &:hover {
    color: var(--bs-link-hover-color)
  }

  &:after {
    font-family: $font-family-icon;
    content: "\ebbd";
    margin-left: 8px;
    margin-top: 1px;
    font-size: 12px;
  }

  &.on-dark {
    @include inverse-link;
  }
}