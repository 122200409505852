*, *:before, *:after {
  box-sizing: border-box;
}

* {
  font-variant-ligatures: none;
}

html {
  width: 100%;
  min-height: 100%;
  scroll-padding-top: $header-height;

  @media all and (max-width: $header-switch-mobile-breakpoint) {
    scroll-padding-top: $header-height;
  }
}

body {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  font-family: $font-family-sans-serif;
  font-weight: 300;
  font-size: $font-size-base;
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);

  &.no-scroll {
    overflow-y: hidden;
  }

  &.full-height {
    overflow: hidden;
    height: 100vh;
  }

  --bs-link-color: #{$link-color};
  --bs-link-hover-color: #{$link-hover-color};
  --bs-table-border-color: #{$table-border-color};
  --bs-header-bg-color: #fff;
  --bs-header-color: #000;
  --bs-header-hover-bg-color: #ebf3f7;
  --header-section-title: #{$header-section-title};
  --header-section-headline: #{$header-section-headline};
  --box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.1);
  --box-shadow-color: rgba(0, 0, 0, 0.1);
  --box-shadow-hover: 0 5px 20px 1px rgba(0, 0, 0, 0.12);
  --dropdown-link-active-color: #{$dropdown-link-active-color};
  --dropdown-link-active-bg-color: rgba(0, 0, 0, 0.1);
  --image-greyscale: grayscale(100%);
  --image-greyscale-hover: none;
  --table-header-color: #{$table-header-bg};
  --wave-shape-color: #{$body-secondary-bg};
  --wave-shape-color-white: #fff;
  --footer-color: #fff;
  --footer-link-hover-color: #{$blue-300};
  --footer-bg: #0d466e;
  --footer-gradient-bg: linear-gradient(180deg, #0d466e, #042737);
  --card-bg: #{$body-bg};
  --card-bg-hover: #{$body-secondary-bg};
  --card-color: #{$body-color};
  --card-color-hover: #{$link-hover-color};
  --card-box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.1);
  --card-box-shadow-hover: 10px 10px 30px 0 rgba(0, 0, 0, 0.1);
  --form-input-bg: #{$form-select-bg};
  --form-input-bg-checked: #5386aa;
  --form-input-color: #000;
  --form-input-border-color: rgba(0, 112, 186, 0.32);
  --logo-background: transparent;
  --blockquote-color: #0f3b66;
  --blockquote-line-color: #b8d6f4;
  --blockquote-bg-color: #{$blue-100};
  --fullpage-form-color-bg: #f8f8f8;
}

@include color-mode(dark) {
  body {
    --bs-link-color: #{$link-color-dark};
    --bs-link-hover-color: #{$link-hover-color-dark};
    --bs-table-border-color: #{$table-border-color-dark};
    --bs-header-bg-color: #04070d;
    --bs-header-color: #fff;
    --bs-header-hover-bg-color: #1c2126;
    --header-section-title: #{$header-section-title-dark};
    --header-section-headline: #{$header-section-headline-dark};
    --box-shadow: 0 5px 20px 1px rgba(255, 255, 255, 0.12);
    --dropdown-link-active-color: #{$dropdown-link-active-color-dark};
    --dropdown-link-active-bg-color: rgba(255, 255, 255, 0.1);
    --image-greyscale: grayscale(100%) invert();
    --table-header-color: #000;
    --wave-shape-color: #1b2834;
    --wave-shape-color-white: #1b2834;
    --card-bg: #{$body-bg-dark};
    --card-bg-hover: #{$body-secondary-bg-dark};
    --card-color: #{$body-color-dark};
    --card-color-hover: #{$dropdown-link-active-color-dark};
    --card-box-shadow-color: rgba(255, 255, 255, 0.12);
    --card-box-shadow-hover: 10px 10px 30px 0 rgba(255, 255, 255, 0.1);
    --form-input-bg: #{$form-select-bg-dark};
    --form-input-bg-focused: #{$form-select-bg-dark};
    --form-input-bg-checked: #fff;
    --form-input-color: #fff;
    --form-input-border-color: rgba(0, 112, 186, 0.51);
    --logo-background: #e7e1e1;
    --blockquote-color: #fff;
    --blockquote-line-color: #b8d6f4;
    --blockquote-bg-color: #0a2744;
    --fullpage-form-color-bg: #192128;
  }
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

a {
  &:hover {
    text-decoration: none;
  }
}

a[tabindex] {
  cursor: pointer;
}

p {
  line-height: 1.85rem;
  font-size: 1.125rem;
}

h2 {
  font-size: 2.125rem;
  line-height: 2.3rem;
}

@include media-breakpoint-down(md) {
  .hidden-for-mobile {
    display: none !important;
  }
}

@include media-breakpoint-up(md) {
  .hidden-for-desktop {
    display: none !important;
  }
}

.section-space {
  padding: 100px 0;

  @include media-breakpoint-down(sm) {
    padding: 50px 30px;
  }
}

.generic-layout {
  margin-top: 20px;
  margin-bottom: 100px;

  a {
    font-weight: 400;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 10px;

    .generic-content {
      overflow-wrap: break-word;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  h1, h2 {
    padding-top: 2rem;
    padding-bottom: 1.25rem;
  }

  h3 {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 400px) {
  h1, h2, h3 {
    hyphens: auto;
  }
}

.wrap {
  flex: 1;
}

.standard-section {
  padding: 100px 0;

  h2 {
    font-size: 2.125rem;
    padding-bottom: 1.25rem;
  }
}

.standard-section-header {
  text-align: center;

  h2 {
    font-family: $font-family-monospace;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: var(--header-section-headline);
    margin-bottom: 20px;
    line-height: 1.3em;
    padding: 0;
  }

  h3 {
    font-size: 3em;
    line-height: 2.3rem;
    font-weight: 900;
    color: var(--header-section-title);
    margin-bottom: 2rem;
    padding: 0;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

img.standard-background-image {
  pointer-events: none;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

video {
  width: 100%;
}

.alert-small {
  font-size: 0.8em;
  display: block;
}

.flex-container {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.tab-selection {
  display: flex;
  padding: 0;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;

  $indicator-width: 2px;
  $indicator-bottom-distance: 3px;

  li {
    margin: 4px 8px;

    a, a.nav-link, button, button.nav-link {
      border: none;
      padding: 8px 15px;
      font-family: $font-family-monospace;
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: var(--bs-link-color);
      line-height: 1.3em;
      background-image: linear-gradient($primary, $primary);
      background-size: 0 $indicator-width;
      background-repeat: no-repeat;
      transition: background-size 0.3s;
      background-position: 50% calc(100% - #{$indicator-bottom-distance});

      &:hover {
        color: var(--bs-link-hover-color);
      }

      &.active {
        color: var(--bs-link-hover-color);
        background-size: 100% $indicator-width;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    li {
      a, a.nav-link, button, button.nav-link {
        padding: 8px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    li {
      a, a.nav-link, button, button.nav-link {
        font-size: 13px;
        padding-top: 0;
      }
    }

  }

  @include media-breakpoint-down(sm) {
    li {
      a, a.nav-link, button, button.nav-link {
        font-size: 11px;
      }
    }

  }
}

i {
  font-style: normal;
}

.popover p {
  margin-bottom: 0;
}

.no-script {
  position: fixed;
  text-align: center;
  font-size: 0.9em;
  left: 20px;
  right: 20px;
  padding: 10px;
  bottom: 60px;
  z-index: 20000;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 1px;

  a {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    text-decoration: underline;
  }
}

.icon-ok-circled-green-white {
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='857.662' height='858'%3E%3Cpath d='M372.264 704.78c-3.637-.73-10.106-3.584-13.394-5.91-1.89-1.336-54.144-57.507-116.192-124.9-102.06-110.853-112.964-122.923-114.934-127.225-5.377-11.744-5.376-26.254.003-38.725 2.465-5.714 5.014-8.62 41.97-47.817 24.155-25.621 25.378-26.797 30.915-29.704 11.815-6.204 23.823-5.45 35.137 2.208 2.233 1.512 30.022 31.054 73.7 78.35l70.116 75.925 101.26-109.343C645.296 200.06 630.608 215.6 637.396 211.992c10.21-5.428 20.756-5.428 30.852 0 6.626 3.562 6.828 3.762 46.777 46.485 25.657 27.44 26.658 28.794 29.167 39.485 1.354 5.767 1.332 16.48-.045 22.277-2.642 11.117 8.672-1.668-172.635 195.072-114.89 124.669-168.36 182.201-170.662 183.627-6.6 4.09-11.875 5.685-19.658 5.945-3.966.133-7.985.087-8.93-.103z' style='fill:%23fff;stroke-width:5.62806'/%3E%3Cpath d='M717.14 339q0-16-10-26l-51-50q-11-11-25-11t-25 11l-228 227-126-126q-11-11-25-11t-25 11l-51 50q-10 10-10 26 0 15 10 25l202 202q10 10 25 10t26-10l303-303q10-10 10-25zm140 90q0 117-57 215t-156 156q-99 58-215 58t-216-58q-100-58-155-156t-58-215q-3-117 58-215t155-156q94-58 216-58t215 58q93 58 156 156t57 215z' style='fill:#64C253'/%3E%3C/svg%3E");
  background-size: contain;
  height: 1em;
  width: 1em;
  margin-right: 5px;
  margin-bottom: -0.1em;
}

.popover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
}

.premium-popover-content {
  p {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  div {
    font-family: $font-family-monospace;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: var(--header-section-headline);
    line-height: 1.3em;
    margin-bottom: 8px;
  }

  i {
    color: #FF9A3F;
    font-size: 20px;
  }
}

.hover-html-popover {
  border: unset;
  background-color: unset;
  padding: 0;

  i {
    color: #FF9A3F;
  }
}

button.trial-license {
  cursor: pointer;
}

.carousel-indicators {
  list-style-type: none;
}

.dropdown-menu {
  border-color: var(--bs-border-color);
}

.op-input {
  background-color: var(--form-input-bg);
  color: var(--form-input-color);
  border: 1px solid var(--form-input-border-color);
  border-radius: $border-radius;
  width: 100%;
  padding: 10px;
}

.op-card {
  background-color: var(--card-bg);
  box-shadow: var(--card-box-shadow);
  border: 1px solid var(--bs-border-color);
  border-radius: 25px;
  transition: all 350ms ease-in-out;
  color: var(--card-color);
}

.op-card-with-hover {
  a {
    color: var(--card-color);

    &:hover {
      color: var(--card-color-hover);
    }
  }

  &:hover {
    box-shadow: var(--card-box-shadow-hover);
    color: var(--card-color-hover);
  }
}
